import React from 'react';
import SingleArticle from './SingleArticle';

const Articles = props => (
  <section id="articles" className="articles">
      {props.sources.articles.map((articles,i) => (
        (
          `${articles.description} && ${articles.title}`)
            ? <SingleArticle key={i} articles={articles} passedKey={i} type={props.type} />
            : ''
        )
    )}
  </section>
);

export default Articles