import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

const Content = props => (
  <Fragment>
    {
      props.type === 'home'
      ? <img src={props.articles.urlToImage} className="article-img lazyload" alt={props.articles.title} loading="lazy" />
        : <img data-src={props.articles.urlToImage} className="article-img lazyload" alt={props.articles.title} loading="lazy" />
    }
    <div className="article-content">
      <h2>{props.articles.title}</h2>
      <span>{props.articles.source.name}</span>
      <p>{props.articles.description}</p>
    </div>
  </Fragment>     
);

const SingleArticle = props => (
  <article key={props.passedKey}>

    { 
      props.articles.url.includes('http')
        ? <a href={props.articles.url} rel="noopener noreferrer" target="_blank"><Content articles={props.articles} type={props.type} /></a>
        : <Link to={props.articles.url}><Content articles={props.articles} type={props.type} /></Link>
    }

  </article>
);

export default SingleArticle