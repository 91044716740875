//import blog from '../images/blog.svg';
//import news_feed from '../images/news_feed.svg';
import under_construction from '../images/under_construction.svg';

export default {
		articles: [
			/*{
				"title": "Blog",
				"description": "Latest articles on Marketing, Design and Technology.",
				"url": "https://medium.com/@rubenoliveira",
				"urlToImage": `${blog}`,
				"source": {
					"name": "in Medium"
				}
			},
				{
				"title": "News Feed",
				"description": "Latest news using the News API. Limited to development environment.",
				"url": "/news-feed/",
				"urlToImage": `${news_feed}`,
				"source": {
					"name": "tailored to the owner"
				}
		}*/
		{
			"title": "Under Construction",
			"description": "Can't wait.",
			"url": "/",
			"urlToImage": `${under_construction}`,
			"source": {
				"name": "best is yet to come"
			}
		}
	]
	};
