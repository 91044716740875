import React from 'react';
import { Link } from 'react-router-dom';
import home from '../images/home.svg';

const Stage = (props) => ( 
  <header>
    <Link to="/">
      <img 
        className="home" 
        src={home} 
        alt="home"
        loading="lazy"
      />
    </Link>
  
    <section className="stage">
      <h1>{props.title}</h1>
    </section>
  </header>
  );

export default Stage