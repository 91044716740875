import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import mainArticles from '../../utils/main-articles';
import Footer from '../Footer';
import Stage from '../Stage';
import Articles from '../Articles';

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Ruben Oliveira . com</title>
        <meta name="description" content="Marketing specialist in love with UX/UI Design and Front-End Development." />
      </Helmet>
      <div className="wrapper">
        <Stage
          title="Ruben Oliveira . com"
        />
        <Articles sources={mainArticles} type="home" />
        <Footer />
      </div>
    </Fragment>
    
  );
}

export default Home