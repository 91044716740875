import React from 'react';
import smoothScrolling from '../utils/smooth-scrolling';
import linkedIn from '../images/linkedin.svg';
import arrowUp from '../images/arrow-top.svg';

const Footer = () => {

  let footerCopy = {
    text: 'You can say hi @',
    url: 'https://www.linkedin.com/in/rubennevesoliveira/'
  }

  return (
    <footer>
      <p>{footerCopy.text}</p>
      <div className="contact-me">
        <a href={footerCopy.url} alt="linkedIn" target="_blank" rel="noopener noreferrer">
          <img src={linkedIn} alt="linkedIn-logo lazyload"  loading="lazy" />
        </a>
      </div>
      <img 
        className="arrow" 
        src={arrowUp} 
        alt="arrow-up"
        loading = "lazy"
        onClick={() => smoothScrolling(0)} 
      />
    </footer>
  )
}


export default Footer